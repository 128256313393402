import React, { Component, Fragment } from 'react';
import { Badge } from 'reactstrap';
import OrdersLink from './Orders'
// import axios from 'axios';

class MainInfo extends Component {
  // componentDidMount() {
  //   axios.get('https://9t6xreml13.execute-api.eu-west-2.amazonaws.com/Prod/amazon/hduan/orders')
  //     .then(response => this.setState({ post: response.data }));
  // }
  
  render() {
    return (
      <div>
        <Fragment>
          { <div className="position-relative">
          
            <span className="d-block pb-2 mb-0 h6 text-uppercase text-info font-weight-bold border-bottom">
               获取订单统计和邮寄文件
              <Badge pill color="warning" className="text-uppercase px-2 py-1 ml-3 mb-1 align-middle" style={{ fontSize: '0.75rem' }}>HOT</Badge>
            </span>

            {/* <span className="d-block pb-4 h2 text-dark border-bottom border-gray">Retrieved Orders Summary</span> */}
            {/* <article className="pt-5 text-secondary text-justify" style={{ fontSize: '0.9rem', whiteSpace: 'pre-line' }}>{this.state.post}</article> */}
            
          </div> }
        </Fragment>
        <OrdersLink/>
      </div>
    );
  }
}

export default MainInfo