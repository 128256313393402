import React, { Component, Fragment } from "react";
import Select from 'react-select';
import https_config from '../config/https_config';

import { 
  Input, Container, Row, Col, InputGroup, InputGroupAddon,
  Spinner, ListGroup, ListGroupItem
} from 'reactstrap';
import axios from "axios";
import LoaderButton from "../components/LoaderButton";
import aws_config from "../config/aws_config"

const warehouses = [
  { value: 'UK1', label: 'UK1 仓库 - 剑桥王维 ' },
  { value: 'UK2', label: 'UK2 仓库 - 萨里丁波 ' },
  // { value: 'UK3', label: 'UK3 仓库 - 利物浦寇佳华 ' },
  // { value: 'US1', label: 'US1 仓库 - 犹他李鑫 ' },
  { value: 'CN1', label: 'CN1 仓库 - 上海贺秋雨 ' },
  { value: 'CN2', label: 'CN2 仓库 - 苏州李浩晨 ' },
];

const skuUpdateOptions = [
  { value: 'UPDATE', label: '更新SKU数量' },
  { value: 'SET', label: '设置SKU总数' },
];

const linkPrefix = aws_config.STAGE.END_POINT;

const sortSkusItems = ({ skusItems }) => skusItems.sort((a, b) => a.sku > b.sku ? 1 : -1);

export default class ViewStockPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 50,
      LastEvaluatedKey: '',
      skusItems: [],
      warehouse: { value: 'UK1', label: 'UK1 仓库 - 剑桥王维 ' },
      skusToSearch: '',
      quantityLessThan: '',
      isLoading: false,

      skuToUpdateh: '',
      numToUpdateh: '',
      warehouseToUpdate: '',
      updateOption: { value: 'UPDATE', label: '更新SKU数量' },
      updateStockResult: '无操作',
    };
  }

  validateInput() {
    const valid = this.state.warehouse.value
    && this.state.warehouse.value.length > 0;
    return valid;
  }

  validateManageInput() {
    const valid =  this.state.warehouseToUpdate.value
    && this.state.warehouseToUpdate.value.length > 0
    && this.state.numToUpdateh
    && this.state.skuToUpdateh;
    return valid;
  }

  searchStocks = async () => {
    this.setState({ isLoading: true })
    this.setState({ skusItems: [] })
    this.setState({ LastEvaluatedKey: 'true' })
    try {
      const res = await axios.get(linkPrefix + "/stocks", { 
        headers: https_config.https_headers(),
        params: { 
          limit: this.state.limit, 
          skus: this.state.skusToSearch,
          warehouse: this.state.warehouse.value,
          quantityLessThan: this.state.quantityLessThan,
      }})
      // console.log(res)
      const data = res.data;
      const skusItems = data.items.map(element => {
        return { sku: element[0], warehouse: element[1] ,quantity: element[2] }
      });
      this.setState({ 
        LastEvaluatedKey: data.last_evaluated_key,
        skusItems: sortSkusItems({ skusItems }),
        isLoading: false ,
      })
    } catch (err) {
      // console.log(err)
      if (err.response && (err.response.status == 403 || err.response.status == 401)) {
        alert(`暂无权限或请登入授权: ${err.response.data.Message}`)
      } else {
        alert('Oops, 出错了！检查输入参数, 报告给开发团队')
      }
      this.setState({ 
        isLoading: false,
        LastEvaluatedKey: null,
      })
    }

    while (this.state.skusItems.length === 0 && this.state.LastEvaluatedKey) {
      await this.loadMoreStocks();
    }
  };

  loadMoreStocks = async () => {
    this.setState({ isLoading: true })
    try {
      const res = await axios.get(linkPrefix + "/stocks", { 
        headers: https_config.https_headers(),
        params: { 
        limit: this.state.limit, 
        skus: this.state.skusToSearch,
        warehouse: this.state.warehouse.value,
        quantityLessThan: this.state.quantityLessThan,
        lastEvaluatedKey: this.state.LastEvaluatedKey
      }})
      const data = res.data;
      const skusItems = data.items.map(element => {
        return { sku: element[0], warehouse: element[1] ,quantity: element[2] }
      });
      this.setState({ 
        LastEvaluatedKey: data.last_evaluated_key,
        skusItems: this.state.skusItems.concat(skusItems),
        isLoading: false,
      })
    } catch (err) {
      // console.log(err)
      if (err.response && (err.response.status == 403 || err.response.status == 401)) {
        alert(`暂无权限或请登入授权: ${err.response.data.Message}`)
      } else {
        alert('Oops, 出错了！检查输入参数, 报告给开发团队')
      }
      this.setState({ isLoading: false })
    }
  };

  handleWareHouseChange = warehouse => {
    this.setState({ warehouse }, 
      // () => console.log(this.state));
    )
  };

  handleQuantityLessThanChange = event => {
    this.setState({ quantityLessThan: event.target.value }, 
      // () => console.log(this.state));
    )
  };

  handleSkusToSearchChange = event => {
    this.setState({ skusToSearch: event.target.value }, 
      // () => console.log(this.state))
    )
  };

  handleSkuToUpdateChange = event => {
    this.setState({ skuToUpdateh: event.target.value }, 
      // () => console.log(this.state))
    )
  };

  handleNumToUpdateChange = event => {
    this.setState({ numToUpdateh: event.target.value}, 
    // () => console.log(this.state))
    )
  };
  
  handleSkuUpdateOptionChange = updateOption => {
    this.setState({ updateOption })
    console.log(this.state.updateOption)
  };
  

  handleWareHouseToUpdateChange = warehouseToUpdate => {
    this.setState({ warehouseToUpdate }, 
      // () => console.log(this.state))
    )
  };
  
  updateStock = async () => {
    this.setState({ 
      isLoading: true,
    });
    const warehouse = this.state.warehouseToUpdate.value;
    const sku = this.state.skuToUpdateh;
    await axios({
      method:"put",
      url: linkPrefix + "/stocks/" + warehouse + "/" + sku, 
      data: { 
        quantity: parseInt(this.state.numToUpdateh),
        option: this.state.updateOption.value || 'SET',
      },
      headers: https_config.https_headers()
    }).then(res => {
        console.log(res);
        this.setState({ 
          updateStockResult: `成功 - 仓库[${this.state.warehouse.value}] 
          SKU[${this.state.skuToUpdateh}] 方式[${this.state.updateOption.label}] 数量[${this.state.numToUpdateh}]`
        })
      })
      .catch(e => {
        // console.log(e)
        alert('更新失败')
        if (e.response && (e.response.status == 403 || e.response.status == 401)) {
          this.setState({ updateStockResult: `请登入授权: ${e.response.data.Message}` })
        } else {
          this.setState({ updateStockResult: '失败' })
        }
      });
    this.setState({ isLoading: false })
  };
  render() {
    const { warehouse, warehouseToUpdate, updateOption } = this.state;

    return (
      <main className="my-5 py-5">
      <Container className="px-0">
        <Row noGutters className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative">
          <Col xs={{ order: 2 }} md={{ size: 5, order: 1 }} tag="aside" className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0">
          <div>
            <Fragment>
              { <div className="position-relative">
              
                <span className="d-block pb-2 mb-0 h6 text-uppercase text-info font-weight-bold border-bottom">
                  查看库存
                </span>
                <p>因经费考虑, 每次搜索或加载至多50个SKU</p>
                {/* <span className="d-block pb-4 h2 text-dark border-bottom border-gray">Retrieved Orders Summary</span> */}
                {/* <article className="pt-5 text-secondary text-justify" style={{ fontSize: '0.9rem', whiteSpace: 'pre-line' }}>{this.state.post}</article> */}
                
              </div> }
              { 
                <div className="position-relative pt-3">
                    <Select
                        className="pt-3"
                        placeholder="选择仓库..."
                        value={warehouse}
                        onChange={this.handleWareHouseChange}
                        options={warehouses}
                    />
                    <InputGroup className="pt-3 mt-3">
                      <InputGroupAddon addonType="prepend">Skus</InputGroupAddon>
                      <Input
                            placeholder="可选填: 输入有效格式SKU 如:P_1,T_41,M_2"
                            value={this.state.skusToSearch}
                            onChange={this.handleSkusToSearchChange}
                      />
                    </InputGroup>
                    <p>可搜索多个SKU, 用逗号无空格','区分</p>

                    <InputGroup className="pt-3 mt-3">
                      <InputGroupAddon addonType="prepend">Less</InputGroupAddon>
                      <Input
                            placeholder="可选填: 只显示库存低于多少数量的skus 如:10"
                            value={this.state.quantityLessThan}
                            onChange={this.handleQuantityLessThanChange}
                      />
                    </InputGroup>

                    <LoaderButton
                      disabled={!this.validateInput()}
                      type="submit"
                      isLoading={this.state.isLoading}
                      text="搜索"
                      loadingText="Searching..."
                      color='success'
                      className='button mt-3'
                      onClick={this.searchStocks}
                    />
                </div>  
              }
            </Fragment>
          </div>

          <div className='mt-3 pt-3'>
            <Fragment>
              { <div className="position-relative mt-3">
                <span className="d-block pb-2 mb-0 h6 text-uppercase text-info font-weight-bold border-bottom">
                  更新库存
                </span>
                <p>仅<strong>仓库管理员</strong>使用并更新自己的仓库库存</p>
                {/* <span className="d-block pb-4 h2 text-dark border-bottom border-gray">Retrieved Orders Summary</span> */}
                {/* <article className="pt-5 text-secondary text-justify" style={{ fontSize: '0.9rem', whiteSpace: 'pre-line' }}>{this.state.post}</article> */}
              </div> }
              { 
                <div className="position-relative pt-3">
                    <Select
                        placeholder="选择仓库..."
                        value={warehouseToUpdate}
                        onChange={this.handleWareHouseToUpdateChange}
                        options={warehouses}
                    />

                    <Select
                        className="pt-3 mt-3"
                        placeholder="选择更新方式"
                        value={updateOption}
                        onChange={this.handleSkuUpdateOptionChange}
                        options={skuUpdateOptions}
                    />

                    <InputGroup className="pt-3 mt-3">
                      <InputGroupAddon addonType="prepend">Sku</InputGroupAddon>
                      <Input
                            placeholder="仅输入一个标准格式Sku: 如:T_1"
                            value={this.state.skuToUpdateh}
                            onChange={this.handleSkuToUpdateChange}
                      />
                    </InputGroup>

                    <InputGroup className="pt-3 mt-3">
                      <InputGroupAddon addonType="prepend">数量</InputGroupAddon>
                      <Input
                            placeholder="输入数量: 如:150或-100"
                            value={this.state.numToUpdateh}
                            onChange={this.handleNumToUpdateChange}
                      />
                    </InputGroup>

                    <LoaderButton
                      disabled={!this.validateManageInput()}
                      type="submit"
                      isLoading={this.state.isLoading}
                      text="更新"
                      loadingText="Updating..."
                      color='danger'
                      className='button mt-3'
                      onClick={this.updateStock}
                    />
                    <br></br>  
                    <p>更新库存: {this.state.updateStockResult}</p>
                </div>  
              }
            </Fragment>
          </div>
          </Col>

          <Col xs={{ order: 1 }} md={{ size: 5, offset: 1 }} tag="section" className="py-5 mb-5 py-md-0 mb-md-0">
            <div>
              <Fragment>
                { <div className="position-relative">
                
                  <span className="d-block pb-2 mb-0 h6 text-uppercase text-info font-weight-bold border-bottom">
                    库存列表
                  </span>

                  {this.state.isLoading && <Spinner className='spinner' />}
                    {!this.state.isLoading && 
                    <ListGroup className='mt-3'>
                      {this.state.skusItems.map((item, index) =>
                          <ListGroupItem key={index}>
                            <strong>Sku:</strong> {item.sku}
                            <strong className='ml-3'>仓库:</strong> {item.warehouse}    
                            <strong className='ml-3'>数量:</strong> {item.quantity}  
                          </ListGroupItem>
                      )}
                    </ListGroup>
                  }

                  {this.state.LastEvaluatedKey && 
                    <LoaderButton
                    disabled={!this.state.LastEvaluatedKey}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="加载更多"
                    loadingText="Loading..."
                    color='success'
                    className='button mt-3'
                    onClick={this.loadMoreStocks}
                  />
                  }
                
                </div> }
              </Fragment>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
    );
  }
}