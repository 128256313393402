import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import aws_config from '../config/aws_config'
import OrderList from './OrderList';
import axios from "axios";
import LoaderButton from "../components/LoaderButton";
import https_config from '../config/https_config';

const platforms = [
  { value: 'all', label: 'all' },
];

const accounts = [
  { value: 'dingb', label: '丁波' },
  { value: 'duanhongyi', label: '段鸿易' },
  { value: 'fangl', label: '方琳' },
  { value: 'heqy', label: '贺秋雨' },
  { value: 'huangtian', label: '黄恬' },
  // { value: 'jiafn', label: '贾凤楠'},
  // { value: 'koujh', label: '寇佳华'},
  { value: 'lihc', label: '李浩晨' },
  { value: 'liwl', label: '李汶霖' },
  // { value: 'lix', label: '李鑫' },
  { value: 'liudt', label: '刘丹彤'},
  { value: 'renfang', label: '任放'},
  // { value: 'tianm', label: '田梦'},
  { value: 'wangwei', label: '王维' },
  { value: 'yangyh', label: '杨永鸿' },
  // { value: 'yinj', label: '尹靖' },
  { value: 'zjyk', label: '洲际盈科' },
];

const confirmShippeds = [
  { value: 'false', label: '拉单预览' },
  { value: 'true', label: '确认发货 (只限账户本人)' },
];

const linkPrefix =  aws_config.STAGE.END_POINT + '/orders';
const linkEnd = 'orders';
class OrdersLink extends Component {
  state = { 
      platform: platforms[0],
      account: 'wangwei',
      confirmShipped: confirmShippeds[0],
      link: '',
      orderResponse: null,
      isLoading: false,
      status: null,
      operation: '',
  }

  invokeGetOrders = async () => {
    this.setState({ isLoading: true })
    try {
      const link = `${linkPrefix}/${this.state.platform.value}/${this.state.account.value}`
      console.log(this.state.link)
      const res = await axios.get(link, { 
        headers: https_config.https_headers(),
        params: { 
          confirm_shipped: this.state.confirmShipped.value
      }})
      const data = res.data;
      //console.log(res)
      this.setState({ 
        orderResponse: data,
        isLoading: false,
        status: res.status,
        operation: this.state.confirmShipped.value == 'true' ? "确认发货":"拉单预览",
      })
      //console.log(this.state)
    } catch (err) {
      // console.log(err.response)
      if (err.response && (err.response.status == 403 || err.response.status == 401))  {
        alert(`暂无权限或请登入授权: ${err.response.data.Message}`)
        this.setState({ 
          isLoading: false,
          orderResponse: null,
          status: `暂无权限或请登入授权: ${err.response.data.Message}`,
          operation: this.state.confirmShipped.value == 'true' ? "确认发货":"拉单预览",
        })
      }
      else {
        this.setState({ 
          isLoading: false,
          orderResponse: null,
          status: err.message,
          operation: this.state.confirmShipped.value == 'true' ? "确认发货":"拉单预览",
        })
      }
    }
  }

  handleAccountChange = account => {
    console.log(this.state.link)
    this.setState({ account });
    this.setState({ link: 
      `${linkPrefix}/${this.state.platform.value}/${account.value}/${linkEnd}?confirm_shipped=${this.state.confirmShipped.value}`});
  };

  handleSonfirmShippedChange = confirmShipped => {
    console.log(this.state.link)
    this.setState({ confirmShipped });
    this.setState({ link: 
      `${linkPrefix}/${this.state.platform.value}/${this.state.account.value}/${linkEnd}?confirm_shipped=${confirmShipped.value}`});
  };

  render() {
    const { account, confirmShipped } = this.state;
    return (
        <Fragment>
            { <div className="position-relative pt-3">
                {/* <Select
                    className="pt-3"
                    placeholder="Select Platform..."
                    value={platform}
                    onChange={this.handlePlatformChange}
                    options={platforms}
                /> */}

                <Select
                    className="pt-3"
                    placeholder="选择账户..."
                    value={account}
                    onChange={this.handleAccountChange}
                    options={accounts}
                    isSearchable={true}
                />        

                <Select
                    className="pt-3"
                    placeholder="确认已发货..."
                    value={confirmShipped}
                    onChange={this.handleSonfirmShippedChange}
                    options={confirmShippeds}
                    isSearchable={true}
                />
                <p>如果选择 <strong>"确认发货"</strong>, 请确认将会下载邮寄地址文件, 保存并发送给仓库发货管理员.
                  <strong>你将无法再次查看已确认发货的订单。</strong>
                </p>
                <div className="pt-3">
                  <LoaderButton
                      disabled={!this.state.account.value}
                      type="submit"
                      isLoading={this.state.isLoading}
                      text="获取订单"
                      loadingText="请稍等..."
                      color='success'
                      className='button mt-3'
                      onClick={this.invokeGetOrders}
                    />
                </div>


                <div className="mt-3">
                  <OrderList
                    orderResponse={this.state.orderResponse}
                    status={this.state.status}
                    operation={this.state.operation}
                  />
                </div>
            </div>
        }
        </Fragment>
    );
  }
}

export default OrdersLink