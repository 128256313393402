function https_headers() {
    return {
        "Authorization": localStorage.getItem("token") || 'Authorization',
        "userid": localStorage.getItem("username") || 'userid',
        "realname": localStorage.getItem("realname") || 'realname'
    }
}

export default {
    https_headers
}