import React, { Component, Fragment } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { withRouter } from "react-router";
import "../resources/css/pages/SignupPage.css";
import PropTypes from "prop-types";
import hashPassword from "../utils/auth_utils"
import axios from "axios";
import aws_config from '../config/aws_config'

class SignupPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			id: "", // username
			email: "",
			password: "",
			confirmPassword: "",
			remember: "",
			realname: "",
			completed: null,
			error: null
		};
	}

	validateForm() {
		return (
			this.state.id.length > 0 &&
			this.state.password.length > 0 &&
			this.state.realname.length > 0 &&
			this.state.password === this.state.confirmPassword
		);
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	
	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });
		try {
			const link = aws_config.STAGE.END_POINT + '/register'
			const data = { 
				id: this.state.id,
				password: hashPassword(this.state.password),
				email: this.state.email,
				remember: this.state.password,
				realname: this.state.realname
			};
			console.log(data);
			await axios({
				method:"post",
				url: link,
				data,
				headers: {  
					'Authorization': 'Authorization',
				}
			});
			// console.log(res);
			this.setState({ completed: true });
			this.setState({ isLoading: false });
			localStorage.removeItem("token");
			localStorage.removeItem("username");

			alert("成功，请登入使用更多功能");
		} catch (e) {
			console.log(e.response)
			alert("失败，请重试。");
		}
		
		this.setState({ isLoading: false });
	};

	renderForm() {
		return (
			<form onSubmit={this.handleSubmit}>
				<FormGroup controlId="id" bsSize="large">
					<ControlLabel>Username ／ 用户名</ControlLabel>
					<FormControl autoFocus type="id" placeholder="* 用于登入时输入用户名" value={this.state.id} onChange={this.handleChange} />
				</FormGroup>
				<FormGroup controlId="password" bsSize="large">
					<ControlLabel>Password ／密码</ControlLabel>
					<FormControl value={this.state.password} placeholder="*" onChange={this.handleChange} type="password" />
				</FormGroup>
				<FormGroup controlId="confirmPassword" bsSize="large">
					<ControlLabel>Password ／ 确认密码</ControlLabel>
					<FormControl value={this.state.confirmPassword} placeholder="* 与密码保持一致" onChange={this.handleChange} type="password" />
				</FormGroup>
				{/* <FormGroup controlId="remember" bsSize="large">
					<ControlLabel>Remember Code ／ 确认码</ControlLabel>
					<FormControl value={this.state.remember} placeholder="* 修改密码时必须输入注册时填写的确认码" onChange={this.handleChange}/>
				</FormGroup> */}
				<FormGroup controlId="email" bsSize="large">
					<ControlLabel>Email ／ 邮箱</ControlLabel>
					<FormControl autoFocus type="email" placeholder="" value={this.state.email} onChange={this.handleChange} />
				</FormGroup>
				<FormGroup controlId="realname" bsSize="large">
					<ControlLabel>Name ／ 真实姓名</ControlLabel>
					<FormControl autoFocus type="realname" placeholder="*" value={this.state.realname} onChange={this.handleChange} />
				</FormGroup>
				<LoaderButton
					block
					disabled={!this.validateForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="注册账户"
                    loadingText="注册中…"
                    color='warning'
                    onClick={this.handleSubmit}
				/>
			</form>
		);
	}

	render() {
		return (
			<div>
				<Fragment>
					{ 
					<div>
						<div className="position-relative">
							<span className="d-block pb-2 mb-0 h6 text-uppercase text-info font-weight-bold border-bottom">
							创建账户 ／ 修改密码
							</span>
							<div className="SignupPage">
								<div className="lander">
									<h3>Welcome to TechLink</h3>
									<p>请输入有效用户名和密码</p>
									{this.renderForm()}
									<br></br>
									<h5>									
										{this.state.completed ? "您已经完成注册，请登入": "请按提示完成注册, '*' 为必填内容"}
									</h5>
								</div>
							</div>
						</div>
					</div>
					}
				</Fragment>
			</div>

		);
	}
}

SignupPage.propTypes = {
	userHasAuthenticated: PropTypes.bool,
	history: PropTypes.object,
};
export default withRouter(SignupPage);