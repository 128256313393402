import React from 'react';
import logo from '../logo.svg';
import techlink from '../resources/techlink.png';
import Routes from './Routes';

import {
  Container, Row, Col, Navbar, Nav,
  NavbarBrand, NavLink, NavItem
} from 'reactstrap';


const Header = () => {
  return (
    <header>
      <Navbar fixed="top" color="light" light expand="xs" className="border-bottom border-gray bg-white" style={{ height: 80 }}>
      
        <Container>
          <Row noGutters className="position-relative w-100 align-items-center">
          
            <Col className="d-none d-lg-flex justify-content-start">
              <Nav className="mrx-auto" navbar>
              
                <NavItem className="d-flex align-items-center">
                  <NavLink className="font-weight-bold" href="/">
                    <img src={techlink} alt="TechLink" className="img-fluid rounded-circle" style={{ width: 80 }} />
                  </NavLink>
                </NavItem>
                
                <NavItem className="d-flex align-items-center">
                  <NavLink className="font-weight-bold" href="/">Home</NavLink>
                </NavItem>

                <NavItem className="d-flex align-items-center">
                  <NavLink className="font-weight-bold" href="/orders">Orders</NavLink>
                </NavItem>
                
                <NavItem className="d-flex align-items-center">
                  <NavLink className="font-weight-bold" href="/viewstock">Stocks</NavLink>
                </NavItem>
                
              </Nav>
            </Col>
            
            <Col className="d-flex justify-content-xs-start justify-content-lg-center">
              <NavbarBrand className="d-inline-block p-0" href="/" style={{ width: 80 }}>
                <img src={logo} alt="logo" className="position-relative img-fluid" />
              </NavbarBrand>
              
            </Col>
            
            <Col className="d-none d-lg-flex justify-content-end">
              <NavItem className="d-flex align-items-center">
                <NavLink className="font-weight-bold text-info" href="/loginout">
                  {localStorage.getItem("token") ? `欢迎，${localStorage.getItem("username")}` : "Log In 登入"}</NavLink>
              </NavItem>

              <NavItem className="d-flex align-items-center">
                
                <NavLink className="font-weight-bold text-danger" href={localStorage.getItem("token") ? "/loginout" : "/signup"}>
                  {localStorage.getItem("token") ? "Log Out 退出" : "Register 注册"}
                </NavLink>
                
              </NavItem>
            </Col>
            
            
          </Row>
        </Container>
        
      </Navbar>
      <Routes />
    </header>
  );
};

export default Header;