import React, { Component, Fragment } from "react";
import LoaderButton from "../components/LoaderButton";
import LoginForm from "../components/LoginForm";
import "../resources/css/pages/LoginoutPage.css";
import PropTypes from "prop-types";

class LoginoutPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: false,
			isLoading: false,
		};
	}

	userHasAuthenticated = authenticated => {
		this.setState({ isAuthenticated: authenticated });
	};

	async componentDidMount() {
		try {
			const currentUser = localStorage.getItem("token");

			if (currentUser) {
				this.userHasAuthenticated(true);
				// console.log(currentUser);
			} else {
				this.userHasAuthenticated(false);
			}
		} catch (e) {
			alert(e);
		}
		this.setState({ isLoading: false });
	}

	handleLogout = async () => {
		this.userHasAuthenticated(false);
		localStorage.removeItem("token");
		localStorage.removeItem("username");
		window.location.reload();
		// this.props.history.push("/loginout"); 
		// console.log("Logged out successfully");
	};

	renderLogin() {
		return (
			<div className="lander">
				<h3>Welcome to TechLink</h3>
				<p>请登入以使用更多功能</p>
				<LoginForm/>
			</div>
		);
	}

	renderLoggedIn() {
		return (
			<div className="lander">
				<h3>Welcome to TechLink</h3>
				<h4>您已登入, 可以使用拉单等更多功能</h4>
				<p>若授权失败或无法使用，请尝试退出重新登入</p>

				<LoaderButton
						bsSize="large"
						type="submit"
						text="退出登入"
						color='danger'
						onClick={this.handleLogout}
				/>
			</div>
		);
	}

	render() {
		return (
			<div>
				<Fragment>
				{ <div className="position-relative">
					<span className="d-block pb-2 mb-0 h6 text-uppercase text-info font-weight-bold border-bottom">
					Access Your Account
					</span>
					<div className="LoginoutPage">{this.state.isAuthenticated ? this.renderLoggedIn() : this.renderLogin()}
					</div>
				</div> }
				</Fragment>
			</div>
    );
	}
}

LoginoutPage.propTypes = {
	history: PropTypes.object,
};

export default LoginoutPage;