import React, { Component } from "react";
import { 
    ListGroup, ListGroupItem
  } from 'reactstrap';

import "../resources/css/components/OrderList.css";

class OrderList extends Component {
    constructor(props) {
        super(props);
    };
    
	render() {
		return (
            <div>
                {this.props.status && this.props.status === 200
                    && <p><strong>成功响应 {this.props.status}</strong>: [{this.props.operation}] 操作成功！</p>
                }
                {this.props.status && this.props.status !== 200 &&
                     <p> 
                        <strong>
                            错误响应 {this.props.status}
                        </strong>
                        <br></br>
                        [{this.props.operation}] 操作失败，请稍后重试，如多次失败请联系软件团队处理！
                    </p>  
                }
                {this.props.orderResponse && this.props.orderResponse['订单详情'] && this.props.orderResponse['订单详情'].length > 0 &&
                    <ListGroup>
                        {this.props.orderResponse['订单详情'].map((order, index) =>
                            <div className='mt-3' key={'order-total-' + index}>
                                <ListGroupItem key={'main-' + index}>
                                    <strong>仓库:</strong> {order['仓库']}
                                    <strong className='ml-3'>邮寄详情下载链接: </strong> <a href={order['邮寄详情下载链接']}>点击下载</a>    
                                </ListGroupItem>
                                <ListGroupItem key={'count-' + index}>
                                    <strong>总订单数:</strong> {order['仓库统计']['总订单数']}
                                    <strong className='ml-3'>总产品数: </strong> {order['仓库统计']['总产品数']}
                                    <strong className='ml-3'>总销售额: </strong> 
                                        {
                                            Object.keys(order['仓库统计']['总销售额']).map(
                                                key => `${order['仓库统计']['总销售额'][key]} ${key} | `
                                            )
                                        }
                                </ListGroupItem>
                                <ListGroupItem key={'delivery-' + index}>
                                    <strong>邮寄方式统计: </strong>
                                    <br></br>
                                        {
                                            order['仓库统计']['邮寄方式统计'].split(',').filter(item => !item.includes('X 0')).map(
                                                item => 
                                                <div className='ml-3' key={'product-mail-' + item}>
                                                    {`${item}`}
                                                </div>
                                            )
                                        }
                                </ListGroupItem>
                                <ListGroupItem key={'product-type-' + index}>
                                    <strong>款式总数: </strong> 
                                    <br></br>
                                        {
                                            Object.keys(order['仓库统计']['款式总数']).filter(key => order['仓库统计']['款式总数'][key]).map(
                                                (key) => 
                                                <div className='ml-3' key={'product-item-type-' + key}>
                                                    <strong>{`${key}`}</strong> {`: ${order['仓库统计']['款式总数'][key]}`}
                                                </div>
                                            )
                                        }
                                </ListGroupItem>
                                <ListGroupItem key={'product-' + index}>
                                    <strong>款式统计: </strong> 
                                    <br></br>
                                        {
                                            Object.keys(order['仓库统计']['款式统计']).filter(key => order['仓库统计']['款式统计'][key]).map(
                                                (key) => 
                                                <div className='ml-3' key={'product-item-' + key}>
                                                    <strong>{`${key}`}</strong> {`: ${order['仓库统计']['款式统计'][key]}`}
                                                </div>
                                            )
                                        }
                                </ListGroupItem>
                            </div>
                        )}
                    </ListGroup>
                }
                {this.props.orderResponse && this.props.orderResponse['message'] &&
                    <strong>
                        消息: {this.props.orderResponse['message']}
                    </strong>
                }
            </div>
           
        )
	}
}

export default OrderList;