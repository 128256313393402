import React, { Component } from "react";
import { Button, Spinner } from "reactstrap";
import "../resources/css/components/LoaderButton.css";

class LoaderButton extends Component {
	render() {
		return (
			<Button 
				className={`LoaderButton ${this.props.className}`}
				onClick={this.props.onClick} 
				color={this.props.color} 
				disabled={this.props.disabled || this.props.isLoading}>

				{this.props.isLoading && <Spinner size="sm"/>}
				{!this.props.isLoading ? this.props.text : this.props.loadingText}
			</Button>
		);
	}
}

export default LoaderButton;