import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AppliedRoute from './AppliedRoute';

import HomePage from '../containers/HomePage';
import LoginoutPage from '../containers/LoginoutPage';
import SignupPage from '../containers/SignupPage';
import NotFoundPage from '../containers/NotFoundPage';
import ViewStockPage from '../containers/ViewStockPage';

export default ({ childProps }) => (
	<Switch>
		<AppliedRoute path="/" exact component={LoginoutPage} props={childProps} />
		<AppliedRoute path="/loginout" exact component={LoginoutPage} props={childProps} />
		<AppliedRoute path="/signup" exact component={SignupPage} props={childProps} />
		<AppliedRoute path="/orders" exact component={HomePage} props={childProps} />
		<AppliedRoute path="/viewstock" exact component={ViewStockPage} props={childProps} />
		{/* Finally, catch all unmatched routes */}
		<Route component={NotFoundPage} />
	</Switch>
);
