import React, { Component } from "react";
import { Form, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Button } from "reactstrap";
import LoaderButton from "../components/LoaderButton";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import "../resources/css/components/LoginForm.css";
import hashPassword from "../utils/auth_utils"
import axios from "axios";
import aws_config from '../config/aws_config';
import https_config from '../config/https_config';

class LoginForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			id: "",
			password: "",
			error: null
		};
	}

	validateForm() {
        return this.state.id.length > 0 
        && this.state.password.length > 0;
	}
	
	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		this.setState({ isLoading: true });
		try {
			const link = aws_config.STAGE.END_POINT + '/login';
			console.log(hashPassword(this.state.password));
			const res = await axios.get(link, {
				headers: https_config.https_headers(),
				params: { 
					id: this.state.id,
					password: hashPassword(this.state.password)
			  }})
			// console.log(res)
			if (res.status == 200) {
				const token = res.data.token
				this.setState({ isLoading: false });
				localStorage.setItem("token", token);
				localStorage.setItem("username", this.state.id);
				this.props.history.push("/orders");
				// console.log("Logged in successfully");
				window.location.reload();
			} else {
				this.setState({ 
					isLoading: false,
					error: `Error message ${res.status}: ${res.data.error_type}`
				 });
				alert("登入失败，请确认账户密码");
			}
		} catch (e) {
			if (e.response && e.response.status === 401) {
				alert("登入失败，请确认账户密码");
			}
			else {
				alert("登入失败, 请确认网络连接");
			}
			this.setState({ 
				isLoading: false,
				error: `请确认网络连接: Error message: ${e}`,
			});
		}
	};
	render() {
		return (
			<div className="LoginForm">
				<Form onSubmit={this.handleSubmit}>
					<FormGroup controlId="id" bsSize="large">
						<ControlLabel>Username／用户名</ControlLabel>
						<FormControl autoFocus type="id" value={this.state.id} onChange={this.handleChange} />
					</FormGroup>
					<FormGroup controlId="password" bsSize="large">
						<ControlLabel>Password ／ 密码</ControlLabel>
						<FormControl value={this.state.password} onChange={this.handleChange} type="password" />
					</FormGroup>
					<LoaderButton
						block
						disabled={!this.validateForm()}
						type="submit"
						isLoading={this.state.isLoading}
						text="登入"
						loadingText="登入中…"
						color='info'
						className='font-weight-bold'
						onClick={this.handleSubmit}
					/>
					<Button color="success" className="ml-1" href='/signup'>注册</Button>
				</Form>
				<br></br>
				<h3>
					{this.state.error}
				</h3>
			</div>
			
		);
	}
}

LoginForm.propTypes = {
  userHasAuthenticated: PropTypes.bool,
  history: PropTypes.object,
};

export default withRouter(LoginForm);
