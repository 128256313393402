const STAGE = {
    ALPHA:{
        END_POINT: 'https://api.continental-techlink.com/alpha',
    },
    BETA: {
        END_POINT: 'https://api.continental-techlink.com/beta',
    },
    // PROD: {
    //     ORDER_API_GATEWAY_HOST: 'https://9t6xreml13.execute-api.eu-west-2.amazonaws.com/Prod'
    // },
}

export default {
    STAGE: STAGE.BETA
}