import React from 'react';
import { Container, Row } from 'reactstrap';

export default () => (
	<main className="my-5 py-5">
      <Container className="px-0">
        <Row noGutters className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative">
        
			<div className="NotFound">
				<h3>Sorry, page not found!</h3>
			</div>
		</Row>
      </Container>
    </main>
);
