import React, { Fragment  } from 'react';
import { useAlert } from "react-alert";

import {
  Button, UncontrolledAlert, Card, CardImg, CardBody,
  CardTitle, CardSubtitle, CardText
} from 'reactstrap';

const BANNER = 'https://i.imgur.com/CaKdFMq.jpg';

const SideCard = () => {
  const alert = useAlert();
  return (
    
  <Fragment>

    <UncontrolledAlert color="info" className="d-none d-lg-block">
      <strong>Welcome to TechLink Web APP.</strong>
    </UncontrolledAlert>
    
    <Card>
      <CardImg top width="100%" src={BANNER} alt="banner" />
      <CardBody>
        <CardTitle className="h3 mb-2 pt-2 font-weight-bold text-secondary">Tech Link</CardTitle>
        <CardSubtitle className="text-secondary mb-3 font-weight-light text-uppercase" style={{ fontSize: '0.8rem' }}> Group:...</CardSubtitle>
        <CardText className="text-secondary mb-4" style={{ fontSize: '0.75rem' }}></CardText>
        <Button 
          color="success" 
          className="font-weight-bold"
          onClick={() => {alert.show('We are the Best')}}
          >Learn More
        </Button>
      </CardBody>
    </Card>
    
  </Fragment>
  );
}


export default SideCard;